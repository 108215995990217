@tailwind base;
@tailwind components;
@tailwind utilities;



html, body {
  height: 100%;
  margin: 0;
  background-color: #000000; /* Black */
}

