/* Hero.css */
@keyframes gradientAnimation {
    0% { background-position: 0% 50%; }
    50% { background-position: 100% 50%; }
    100% { background-position: 0% 50%; }
  }
  
  .animated-gradient {
    background: linear-gradient(90deg, #8B40E2 0%, #7A90E8 50%, #76D5EF 100%);
    background-size: 200% 200%;
    animation: gradientAnimation 5s ease infinite;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    display: inline-block;
  }
  